import React, {useState} from "react";
import {Button, Form, Input, Modal} from "antd";
import WithdrawRequestService, {AdminWithdrawRequestOutputModel} from "../../../../services/WithdrawRequestService";
import {WithdrawStatus} from "../../../../constans/enums";
import TextArea from "antd/es/input/TextArea";
import {openSuccessNotification} from "../../../../services/UtilFunctions";

interface Props {
    show: boolean;

    setShow(b: boolean): void;

    data: AdminWithdrawRequestOutputModel | undefined;
    action?: WithdrawStatus;
}

export const WithdrawRequestUpdate = ({show, setShow, data, action}: Props) => {

    const [form] = Form.useForm()
    const [loading, setLoading] = useState<boolean>(false);

    const onFinish = (values: any) => {
        if (data) {
            if (action === WithdrawStatus.APPROVED) {
                setLoading(true);
                WithdrawRequestService.approveWithdrawRequest(data.id, {
                    trackId: values.trackId,
                    adminComment: values.adminComment
                })
                    .then(value => {
                        form.resetFields();
                        setShow(false);
                        setLoading(false);
                        openSuccessNotification();
                    })
                    .finally(() => setLoading(false));
            } else if (action === WithdrawStatus.REJECTED) {
                setLoading(true);
                WithdrawRequestService.rejectWithdrawRequest(data.id, {adminComment: values.adminComment})
                    .then(value => {
                        form.resetFields();
                        setShow(false);
                        setLoading(false);
                        openSuccessNotification();
                    })
                    .finally(() => setLoading(false));
            }
        }
    }

    if (!action) return (<></>);

    return (
        <Modal
            title={action === WithdrawStatus.APPROVED ? 'تایید' : 'رد'}
            centered
            open={show}
            onOk={() => {
            }}
            onCancel={() => {
                form.resetFields();
                setShow(false);
            }}
            footer={[]}
        >
            <Form
                form={form}
                name="basic"
                layout='vertical'
                labelCol={{span: 8}}
                wrapperCol={{span: 16}}
                style={{maxWidth: 600}}
                initialValues={{remember: true}}
                onFinish={onFinish}
                autoComplete="off"
            >
                {
                    action === WithdrawStatus.APPROVED &&
                    <Form.Item
                        name="trackId"
                        label="کد رهگیری"
                    >
                        <Input placeholder="کد رهگیری"/>
                    </Form.Item>
                }
                <Form.Item
                    name="adminComment"
                    label="پیام"
                >
                    <TextArea placeholder="پیام"/>
                </Form.Item>
                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        {action === WithdrawStatus.APPROVED ? 'تایید' : 'رد'}
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );

}