import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommasToman, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import {
    TransactionStatusTitle,
    TransactionType,
    TransactionTypeColor,
    TransactionTypeTitle
} from "../../../../constans/enums";
import TransactionService, {TransactionOutputModel} from "../../../../services/TransactionService";

export const UserTransactionContainer: React.FC = () => {

    const [data, setData] = useState<Page<TransactionOutputModel>>();
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });

    useEffect(() => {
        loadData();
    }, [params]);

    const loadData = () => {
        TransactionService.getSelfTransactions(params).then(page => {
            setData(page)
        });
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<TransactionOutputModel> = [
        {
            title: 'تاریخ',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'شماره پیگیری شاپرک',
            dataIndex: 'shaparakRefId',
            width: 200
        },
        {
            title: 'مبلغ',
            dataIndex: 'finalPrice',
            width: 200,
            render: (_, {finalPrice, type}) => {
                return <span style={{color: TransactionTypeColor[type]}}>{numberWithCommasToman(finalPrice)}</span>
            },
        },
        {
            title: 'وضعیت',
            dataIndex: 'status',
            width: 200,
            render: (_, {status}) => (
                TransactionStatusTitle[status]
            ),
        },
        {
            title: 'نوع',
            dataIndex: 'type',
            width: 200,
            render: (_, {type}) => (
                TransactionTypeTitle[type]
            ),
        },
    ];

    return (
        <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
            <div style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>تراکنش ها</div>
            <Divider style={{margin: '0', marginTop: '15px'}}/>
            <Table scroll={{x: 750}} pagination={{
                current: data?.number ? data?.number + 1 : 1,
                pageSize: params.pageSize,
                total: data?.totalElements,
                onChange: handleSelectPagination
            }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
        </Wrapper1>
    );
}