import React, {useState} from "react";
import {Col, Layout, MenuProps, Drawer} from 'antd';
import {HOME_PAGE_ROUTE} from "../../routes/route-path";
import logo from "../../assets/images/logo1.png";
import {DashboardSideMenu} from "../../components/dashboard/DashboardSideMenu";
import {CategoryContainer} from "../../components/dashboard/admin/category/CategoryContainer";
import {CourseContainer} from "../../components/dashboard/admin/course/CourseContainer";
import {UserContainer} from "../../components/dashboard/admin/user/UserContainer";
import {FORTH_COLOR, SEVENTH_COLOR, THIRD_COLOR} from "../../constans/colors";
import {PlanContainer} from "../../components/dashboard/admin/plan/PlanContainer";
import {UserPlanContainer} from "../../components/dashboard/admin/user-plan/UserPlanContainer";
import {toPersianDate} from "../../services/UtilFunctions";
import {DiscountCodeContainer} from "../../components/dashboard/admin/discount-code/DiscountCodeContainer";
import {CourseCommentContainer} from "../../components/dashboard/admin/course-comment/CourseCommentContainer";
import {ContactUsContainer} from "../../components/dashboard/admin/contact-us/ContactUsContainer";
import {NotificationContainer} from "../../components/dashboard/admin/notification/NotificationContainer";
import {CooperationFormContainer} from "../../components/dashboard/admin/cooperation-form/CooperationFormContainer";
import {TopCoursesContainer} from "../../components/dashboard/admin/top-courses/TopCoursesContainer";
import {WatchReportContainer} from "../../components/dashboard/admin/watch-report/WatchReportContainer";
import {TeacherPaymentContainer} from "../../components/dashboard/admin/teacher-payment/TeacherPaymentContainer";
import {FileManagementContainer} from "../../components/dashboard/admin/file-management/FileManagementContainer";
import {
    CommentOutlined,
    DollarOutlined,
    FileOutlined,
    MenuOutlined,
    MoneyCollectOutlined,
    NotificationOutlined,
    PercentageOutlined,
    PhoneOutlined,
    PieChartOutlined,
    ProfileOutlined,
    ReadOutlined,
    ShoppingOutlined,
    StarOutlined,
    TeamOutlined,
    TransactionOutlined,
    UserOutlined
} from "@ant-design/icons";
import {AdminTransactionContainer} from "../../components/dashboard/admin/transactions/AdminTransactionContainer";
import {useMedia} from "react-use";
import {WithdrawRequestContainer} from "../../components/dashboard/admin/withdraw-request/WithdrawRequestContainer";

type MenuItem = Required<MenuProps>['items'][number];

const {Header, Footer, Sider, Content} = Layout;

const headerStyle: React.CSSProperties = {
    textAlign: 'center',
    color: '#fff',
    height: 60,
    paddingInline: 50,
    marginBottom:"20px",
    lineHeight: '64px',
    padding:'0px 10px',
    backgroundColor: SEVENTH_COLOR,
};

function getItem(
    label: React.ReactNode,
    key: React.Key,
    icon?: React.ReactNode,
    children?: MenuItem[],
    type?: 'group',
): MenuItem {
    return {
        key,
        icon,
        children,
        label,
        type,
    } as MenuItem;
}
export const AdminDashboardPage = () => {

    const [activeComponent, setActiveComponent] = useState<React.ReactNode>(<CategoryContainer/>)
    const isSmall = useMedia('(max-width: 768px)', true);
    const [isOpenStyledDrawer, setIsOpenStyledDrawer] = useState<boolean>(false);
    const [selectedKey, setSelectedKey] = useState<string>("key1")

    const items: MenuItem[] = [
        getItem((<a onClick={() => handleSetActiveComponent(<CategoryContainer/>, "key1")}>دسته بندی</a>), "key1", <ProfileOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<CourseContainer/>, "key2")}>دوره ها</a>), "key2", <ReadOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<TopCoursesContainer/>, "key11")}>دوره‌های برتر</a>), "key11", <StarOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<UserContainer/>, "key3")}>کاربران</a>), "key3", <UserOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<PlanContainer/>, "key4")}>اشتراک</a>), "key4", <MoneyCollectOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<UserPlanContainer/>, "key5")}>اشتراک های خریداری شده</a>), "key5", <ShoppingOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<DiscountCodeContainer/>, "key6")}>کد های تخفیف</a>), "key6", <PercentageOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<CourseCommentContainer/>, "key7")}>نظرات دوره</a>), "key7", <CommentOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<ContactUsContainer/>, "key8")}>تماس با ما</a>), "key8", <PhoneOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<NotificationContainer/>, "key9")}>اعلان ها</a>), "key9", <NotificationOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<CooperationFormContainer/>, "key10")}>تدریس در دانشگاهینو</a>), "key10", <TeamOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<WatchReportContainer/>, "key12")}>گزارش مدرس</a>), "key12", <PieChartOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<TeacherPaymentContainer/>, "key13")}>مالی مدرسان</a>), "key13", <DollarOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<FileManagementContainer/>, "key14")}>مدیریت فایل ها</a>), "key14", <FileOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<AdminTransactionContainer/>, "key15")}>تراکنش ها</a>), "key15", <TransactionOutlined />),
        getItem((<a onClick={() => handleSetActiveComponent(<WithdrawRequestContainer/>, "key16")}>درخواست برداشت وجه</a>), "key16", <DollarOutlined />),
    ]

    const handleSetActiveComponent = (component : React.ReactNode, key : string) => {
        setActiveComponent(component);
        setIsOpenStyledDrawer(false);
        setSelectedKey(key);
    }

    const closeStyledDrawer = () => {
        setIsOpenStyledDrawer(false);
    }


    return (
        <Layout>
            {
                isSmall ?
                    <Drawer
                        onClose={closeStyledDrawer}
                        open={isOpenStyledDrawer}
                        placement="right"
                        destroyOnClose
                        width={340}
                    >
                        <Sider style={{
                            textAlign: 'right',
                            backgroundColor: FORTH_COLOR,
                            padding:"35px 5px",
                            fontSize:"30px",
                            overflow: 'hidden',
                        }} width={280}>
                            <DashboardSideMenu selectedKey={selectedKey} items={items} style={{fontSize:"18px",borderInlineEnd:"none"}} mode="inline"/>
                        </Sider>
                    </Drawer> :
                    <Sider style={{
                        textAlign: 'right',
                        backgroundColor: FORTH_COLOR,
                        padding:"35px 5px",
                        fontSize:"30px",
                        overflow: 'hidden',
                    }} breakpoint={"md"} width={280} collapsedWidth={50}>
                        <DashboardSideMenu selectedKey={selectedKey} items={items} style={{fontSize:"18px",borderInlineEnd:"none"}} mode="inline"/>
                    </Sider>
            }
            <Layout style={{minHeight: "100vh"}}>
                <Header style={headerStyle}>
                    {isSmall ? (
                        <div style={{float: "right"}}>
                            <MenuOutlined onClick={() => {
                                setIsOpenStyledDrawer(true);
                            }} style={{color:THIRD_COLOR, fontSize:'30px', verticalAlign:'middle', cursor:'pointer'}} />
                        </div>
                    ) : null}
                    <a href={HOME_PAGE_ROUTE}><img src={logo} alt="logo" style={{
                        height: "100%",
                        padding: "10px 16px",
                        float: "right"
                    }}/></a>
                    <span style={{color: THIRD_COLOR, float: "right"}}>دانشگاهینو / پنل ادمین</span>
                    <Col xs={0} sm={24}>
                    <span style={{color: THIRD_COLOR, float: "left"}}>
                        {toPersianDate(new Date())}
                        <span style={{padding: "0px 5px"}}>|</span>
                        <a style={{color: THIRD_COLOR}} href={HOME_PAGE_ROUTE}>بازگشت</a>
                    </span>
                    </Col>
                </Header>
                <Content>
                    {activeComponent}
                </Content>
            </Layout>
        </Layout>
    );
}