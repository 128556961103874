import {Card, Typography} from "antd";
import {numberWithCommasTomanAndIncludeZero} from "../../services/UtilFunctions";
import {FIRST_COLOR} from "../../constans/colors";
import {useEffect, useState} from "react";
import WalletService, {WalletOutputModel} from "../../services/WalletService";

const { Text } = Typography;

export const WalletBalance = () => {

    const [wallet, setWallet] = useState<WalletOutputModel>({balance:0, blockedBalance:0});

    useEffect(() => {
        WalletService.getSelfWallet().then(value => setWallet(value));
        window.addEventListener('wallet:updated', handleWalletUpdateEvent
        );

        return () => {
            window.removeEventListener('wallet:updated', handleWalletUpdateEvent);
        };
    }, []);

    const handleWalletUpdateEvent = (e : Event) => {
        WalletService.getSelfWallet().then(value => setWallet(value));
    }

    return (
        <Card
            style={{
                textAlign: "center",
                background: FIRST_COLOR,
                borderRadius: "8px",
                marginBottom:'20px'
            }}
            styles={{
                body: {
                    padding: "10px 10px"
                }
            }}
        >
            <Text style={{ fontSize: "17px", color: "white" }}>موجودی کیف پول شما</Text>
            <br />
            <Text strong style={{ fontSize: "20px", color: "white" }}>
                {numberWithCommasTomanAndIncludeZero(wallet.balance)}
            </Text>
            {
                wallet.blockedBalance > 0 &&
                <>
                    <br/>
                    <Text style={{fontSize: "15px", color: "red"}}>مبلغ بلوکه شده</Text>
                    <br/>
                    <Text strong style={{fontSize: "15x", color: "red"}}>
                        {numberWithCommasTomanAndIncludeZero(wallet.blockedBalance)}
                    </Text>
                </>
            }
        </Card>
    );
};