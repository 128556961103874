import React, {ChangeEventHandler, useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Button, Checkbox, CheckboxChangeEvent, Col, Form, Input, Modal, Row, Space, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {
    numberWithCommasToman,
    openErrorNotification,
    Page,
    toPersianDateTime
} from "../../../../services/UtilFunctions";
import {CardNumberEdit} from "../../../common/CardNumberEdit";
import UserService, {User} from "../../../../services/UserService";
import {ExclamationCircleOutlined} from "@ant-design/icons";
import WithdrawRequestService, {WithdrawRequestOutputModel} from "../../../../services/WithdrawRequestService";
import {WithdrawStatusTitle} from "../../../../constans/enums";
import WalletService, {WalletOutputModel} from "../../../../services/WalletService";

export const WithdrawRequestContainer: React.FC = () => {

    const [data, setData] = useState<Page<WithdrawRequestOutputModel>>();
    const [rawRequestAmount, setRawRequestAmount] = useState<number>(0);
    const [requestAmount, setRequestAmount] = useState<string>("");
    const [useFullBalance, setUseFullBalance] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingSubmit, setLoadingSubmit] = useState<boolean>(false)
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });

    const [wallet, setWallet] = useState<WalletOutputModel>({balance: 0, blockedBalance: 0});
    const [user, setUser] = useState<User>();
    const [requestModal, requestModalContextHolder] = Modal.useModal();


    useEffect(() => {
        WalletService.getSelfWallet().then(value => setWallet(value));
        UserService.getSelfProfile().then(value => setUser(value));
        loadData();
    }, [params]);

    const loadData = () => {
        setLoading(true);
        WithdrawRequestService.getSelfWithdrawRequestPage(params).then(page => {
            setData(page);
        }).finally(() => setLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<WithdrawRequestOutputModel> = [
        {
            title: 'شناسه درخواست',
            dataIndex: 'id',
            width: 150,
        },
        {
            title: 'تاریخ درخواست',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{createdDate && toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'مبلغ',
            dataIndex: 'amount',
            width: 200,
            render: (_, {amount}) => (
                <>{numberWithCommasToman(amount)}</>
            ),
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'status',
            width: 150,
            render: (_, record) => (
                <>{WithdrawStatusTitle[record.status]}</>
            ),
        },
        {
            title: 'شماره کارت',
            dataIndex: 'cardNumber',
            width: 200,
        },
        {
            title: 'کد رهگیری',
            dataIndex: 'trackId',
            width: 300,
        },
        {
            title: 'پیام',
            dataIndex: 'adminComment',
            width: 300,
        }
    ];
    const formatNumber = (value: string): string => {
        return value.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let value = e.target.value.replace(/,/g, ""); // Remove commas for processing

        if (!/^\d*$/.test(value)) return; // Only allow numbers

        let numValue = Number(value);
        if (numValue < 0 || numValue > wallet.balance) return; // Validate range

        setRequestAmount(formatNumber(value));
        setRawRequestAmount(numValue);
    };

    const handleCheckboxChange = (e: CheckboxChangeEvent) => {
        if (e.target.checked) {
            setRequestAmount(formatNumber(wallet.balance.toString()));
            setRawRequestAmount(wallet.balance);
            setUseFullBalance(true);
        } else {
            reset();
        }
    };

    const reset = () => {
        setRequestAmount("");
        setRawRequestAmount(0);
        setUseFullBalance(false);
    }

    return (
        <>
            <div style={{marginRight: "15px", marginBottom: "15px"}}>
                <Space>
                    <CardNumberEdit cardNumber={user?.cardNumber} callBack={() => {
                        UserService.getSelfProfile().then(value => setUser(value));
                    }}/>
                </Space>
            </div>


            <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
                <div style={{
                    color: SECOND_COLOR,
                    fontSize: "18px",
                    fontWeight: 'bold',
                    marginBottom:'10px'
                }}>درخواست برداشت وجه
                </div>
                <div>
                    <Form size="large" layout="vertical">
                        <Form.Item label='مبلغ به تومان'>
                            <Input
                                style={{maxWidth:'300px'}}
                                placeholder="مبلغ را وارد کنید"
                                value={requestAmount}
                                onChange={handleChange}
                                disabled={useFullBalance}
                            />
                        </Form.Item>
                        <Form.Item>
                            <Checkbox checked={useFullBalance} onChange={handleCheckboxChange}>
                                برداشت کل موجودی
                            </Checkbox>
                        </Form.Item>
                        <Form.Item>
                            <Button loading={loadingSubmit} style={{float: 'right'}} size="large" type="primary" onClick={event => {
                                if (!user?.cardNumber) {
                                    openErrorNotification("برای درخواست برداشت وجه، شماره کارت خود را وارد نمایید.")
                                    return;
                                }
                                requestModal.confirm({
                                    title: `مبلغ شما به شماره کارت زیر واریز خواهد شد آن را تایید می نمایید؟`,
                                    content: <span style={{fontWeight: 'bold'}}>{user.cardNumber}</span>,
                                    centered:true,
                                    icon: <ExclamationCircleOutlined/>,
                                    okText: 'تایید',
                                    cancelText: 'انصراف',
                                    onOk: () => {
                                        let amount;
                                        if (useFullBalance) amount = wallet.balance;
                                        if (rawRequestAmount && rawRequestAmount > 0) amount = rawRequestAmount;
                                        if (!amount) {
                                            openErrorNotification('لطفا مبلغ مدنظر خود را وارد کنید');
                                            return;
                                        }
                                        setLoadingSubmit(true);
                                        WithdrawRequestService.requestWithdraw({
                                            amount: amount
                                        }).then(value => {
                                            loadData();
                                            window.dispatchEvent(new CustomEvent('wallet:updated'));
                                            reset();
                                        }).finally(() => {
                                            setLoadingSubmit(false);
                                        })
                                    }
                                })
                            }}
                                    disabled={!wallet || wallet.balance === 0}
                            >
                                درخواست برداشت وجه
                            </Button>
                        </Form.Item>
                    </Form>
                </div>


                <Table rowKey="id" scroll={{x: 1200}} loading={loading} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"
                />
            </Wrapper1>
            {requestModalContextHolder}
        </>


    );
}