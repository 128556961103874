import React, { useEffect, useState } from "react";
import { Input, Button, Typography, message, Alert } from "antd";
import { Wrapper1 } from "../../../wrapper/Wrapper1";
import { THIRD_COLOR } from "../../../../constans/colors";
import { openErrorNotification, openSuccessNotificationByMessage } from "../../../../services/UtilFunctions";
import UserService, { User } from "../../../../services/UserService";

const { Text } = Typography;

const AffiliateLinkContainer = () => {
    const [user, setUser] = useState<User>();
    const [inputLink, setInputLink] = useState("");
    const [affiliateLink, setAffiliateLink] = useState("");

    useEffect(() => {
        loadUser();
    }, []);

    const loadUser = () => {
        UserService.getSelfProfile().then(response => {
            setUser(response);
        });
    };

    const validateAndGenerateAffiliateLink = () => {
        const host = window.location.origin;

        if (!user?.affiliateKey) {
            openErrorNotification("کلید معرفی شما یافت نشد!");
            return;
        }

        if (!inputLink) {
            setAffiliateLink(`${host}/?ref=${user.affiliateKey}`);
            return;
        }

        try {
            const url = new URL(inputLink);
            if (url.origin !== host) {
                openErrorNotification("آدرس وارد شده معتبر نیست. لطفا آدرس یکی از صفحات دانشگاهینو را وارد کنید.");
                return;
            }
            setAffiliateLink(`${url.href}?ref=${user.affiliateKey}`);
        } catch (error) {
            openErrorNotification("لطفا یک آدرس معتبر وارد کنید.");
        }
    };

    const handleCopy = () => {
        if (!affiliateLink) {
            openErrorNotification("هیچ لینکی برای کپی کردن وجود ندارد.");
            return;
        }
        navigator.clipboard.writeText(affiliateLink)
            .then(() => openSuccessNotificationByMessage("لینک معرفی با موفقیت کپی شد!"))
            .catch(() => openErrorNotification("خطا در کپی کردن لینک"));
    };

    return (
        <Wrapper1 style={{ margin: "2rem", marginTop: "0px", padding: "20px", color: THIRD_COLOR }}>
            {user?.affiliateKey && (
                <div style={{ maxWidth: 600, margin: "0 auto", padding: "20px" }}>
                    <Alert
                        message="برای تولید لینک معرفی خود، کافی است یکی از آدرس‌های صفحات دانشگاهینو را در باکس زیر کپی کنید و با زدن دکمه 'لینک معرفی من را بساز' لینک معرفی شما با آدرس مدنظر شما ساخته می‌شود. همچنین، اگر آدرسی را مدنظر ندارید، با زدن روی دکمه، به‌صورت پیش‌فرض، لینک معرفی شما بر روی صفحه اصلی دانشگاهینو ساخته می‌شود."
                        type="info"
                        style={{ marginBottom: 20 }}
                    />
                    <Text strong>آدرس مورد نظر خود را وارد کنید:</Text>
                    <Input
                        value={inputLink}
                        onChange={(e) => setInputLink(e.target.value)}
                        placeholder="آدرس یکی از صفحات دانشگاهینو"
                        style={{ marginBottom: 15, marginTop:5 }}
                    />
                    <Button type="primary" onClick={validateAndGenerateAffiliateLink} style={{ width: "100%", marginBottom: 15 }}>
                        لینک معرفی من را بساز
                    </Button>
                    {affiliateLink && (
                        <>
                            <Text strong>لینک معرفی شما:</Text>
                            <Input value={affiliateLink} readOnly style={{ marginBottom: 15, marginTop:5 }} />
                            <Button type="primary" onClick={handleCopy} style={{ width: "100%" }}>
                                کپی کردن لینک معرفی
                            </Button>
                        </>
                    )}
                </div>
            )}
        </Wrapper1>
    );
};

export default AffiliateLinkContainer;
