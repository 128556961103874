import React, {useEffect, useState} from "react";
import {Wrapper1} from "../../../wrapper/Wrapper1";
import {SECOND_COLOR} from "../../../../constans/colors";
import {Divider, Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {numberWithCommasToman, Page, toPersianDateTime} from "../../../../services/UtilFunctions";
import {WithdrawRequestUpdate} from "./WithdrawRequestUpdate";
import WithdrawRequestService, {AdminWithdrawRequestOutputModel} from "../../../../services/WithdrawRequestService";
import {WithdrawStatus, WithdrawStatusTitle} from "../../../../constans/enums";
import {Space} from "antd/lib";

export const WithdrawRequestContainer: React.FC = () => {

    const [data, setData] = useState<Page<AdminWithdrawRequestOutputModel>>();
    const [loading, setLoading] = useState<boolean>(false);
    const [params, setParams] = useState({
        pageNumber: 0,
        pageSize: 10,
        sortKey: "createdDate",
        sort: "DESC"
    });
    const [showUpdateModal, setShowUpdateModal] = useState<boolean>(false);
    const [selectedRecord, setSelectedRecord] = useState<AdminWithdrawRequestOutputModel>();
    const [selectedRecordAction, setSelectedRecordAction] = useState<WithdrawStatus>();

    useEffect(() => {
        loadData();
    }, [params, showUpdateModal]);

    const loadData = () => {
        setLoading(true);
        WithdrawRequestService.getWithdrawRequestPage(params).then(page => {
            setData(page);
        }).finally(() => setLoading(false));
    }

    const handleSelectPagination = (page: number, pageSize: number) => {
        setParams({
            ...params,
            pageNumber: page - 1,
            pageSize: pageSize
        });
    };

    const columns: ColumnsType<AdminWithdrawRequestOutputModel> = [
        {
            title: 'شناسه درخواست',
            dataIndex: 'id',
            width: 150,
        },
        {
            title: 'تاریخ درخواست',
            dataIndex: 'createdDate',
            width: 200,
            render: (_, {createdDate}) => (
                <>{createdDate && toPersianDateTime(createdDate)}</>
            ),
        },
        {
            title: 'مبلغ',
            dataIndex: 'amount',
            width: 200,
            render: (_, {amount}) => (
                <>{numberWithCommasToman(amount)}</>
            ),
        },
        {
            title: 'نام کاربر',
            dataIndex: 'userFullName',
            width: 150,
            render: (_, {user}) => (
                <>{user && user.firstName + " " + user.lastName}</>
            ),
        },
        {
            title: 'موبایل',
            dataIndex: ['user', 'mobile'],
            width: 150,
        },
        {
            title: 'وضعیت پرداخت',
            dataIndex: 'status',
            width: 150,
            render: (_, {status}) => (
                WithdrawStatusTitle[status]
            ),
        },
        {
            title: 'شماره کارت',
            dataIndex: 'cardNumber',
            width: 200,
        },
        {
            title: 'کد رهگیری',
            dataIndex: 'trackId',
            width: 300,
        },
        {
            title: 'پیام',
            dataIndex: 'adminComment',
            width: 300,
        },
        {
            width: 100,
            render: (_, record) => {
                if (record.status === WithdrawStatus.PENDING) {
                    return <Space size='large'>
                        <span onClick={() => {
                            setSelectedRecord(record);
                            setShowUpdateModal(true);
                            setSelectedRecordAction(WithdrawStatus.APPROVED);
                        }} style={{color: 'green', cursor: 'pointer'}}>
                        تایید
                    </span>

                        <span onClick={() => {
                            setSelectedRecord(record);
                            setShowUpdateModal(true);
                            setSelectedRecordAction(WithdrawStatus.REJECTED);
                        }} style={{color: 'red', cursor: 'pointer'}}>
                        رد
                    </span>
                    </Space>
                } else {
                    return <></>
                }
            }
        },
    ];

    return (
        <>
            <Wrapper1 style={{margin: "15px", marginTop: "0px", padding: "20px"}}>
                <span style={{color: SECOND_COLOR, fontSize: "18px", fontWeight: 'bold'}}>درخواست‌های برداشت وجه</span>
                <Divider style={{margin: '0', marginTop: '15px'}}/>
                <Table rowKey="id" className="admin-course-table" scroll={{x: 1400}} loading={loading} pagination={{
                    current: data?.number ? data?.number + 1 : 1,
                    pageSize: params.pageSize,
                    total: data?.totalElements,
                    onChange: handleSelectPagination
                }} style={{marginTop: "20px"}} columns={columns} dataSource={data?.content} size="middle"/>
            </Wrapper1>
            {
                (() => {
                    return (showUpdateModal ? <WithdrawRequestUpdate action={selectedRecordAction} show={showUpdateModal} setShow={setShowUpdateModal}
                                                                     data={selectedRecord}/> : null)
                })()
            }
        </>


    );
}