import React, {useEffect, useState} from "react";
import {Button, Form, Input} from "antd";
import {openSuccessNotificationByMessage, toEnglishNumber} from "../../services/UtilFunctions";
import UserService from "../../services/UserService";

interface Props{
    cardNumber?:string;
    callBack?:any;
}

export const CardNumberEdit =({cardNumber, callBack}:Props) => {

    const [form] = Form.useForm();
    const [loading, setLoading] = useState<boolean>(false);

    useEffect(() => {
        form.setFieldValue("cardNumber", cardNumber)
    }, [cardNumber])

    const onFinish = (values: any) => {
        if (values.cardNumber){
            values.cardNumber = values.cardNumber.trim();
            values.cardNumber = toEnglishNumber(values.cardNumber);
        }
        if (values.cardNumber === "") values.cardNumber = null;
        setLoading(true);
        UserService.editBankAccount(values).then(response => {
            openSuccessNotificationByMessage("شماره کارت با موفقیت تغییر کرد.");
            if (callBack) callBack();
            setLoading(false);
        }).catch(reason => {
            setLoading(false);
        });
    }

    return(
        <div style={{display:'inline-block'}}>
            <Form
                form={form}
                name="basic"
                layout="vertical"
                // labelCol={{ span: 8 }}
                // wrapperCol={{ span: 16 }}
                // style={{ maxWidth: 600 }}
                onFinish={onFinish}
                initialValues={{ remember: true }}
                autoComplete="off"
                size="large"
            >
                <Form.Item
                    name="cardNumber"
                    initialValue={cardNumber}
                    rules={[
                        {
                            validator: (_, value) => {
                                if (/^\d{16}$/.test(value) || value === undefined || value === null || value === "") {
                                    return Promise.resolve();
                                } else {
                                    return Promise.reject('شماره کارت نامعتبر است.');
                                }
                            }
                        }
                    ]}
                    label="شماره کارت(از کیبورد انگلیسی استفاده نمایید)"
                >
                    <Input name="cardNumber" placeholder="شماره کارت"/>
                </Form.Item>

                <Form.Item>
                    <Button type="primary" htmlType="submit" loading={loading}>
                        تغییر
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );

}