export enum Gender {
    MALE = 'MALE',
    FEMALE = 'FEMALE'
}

export enum GenderTitle {
    MALE = 'مرد',
    FEMALE = 'زن'
}

export enum IntroductionMethod {
    SOCIAL_MEDIA = 'SOCIAL_MEDIA',
    FRIENDS = 'FRIENDS',
    UNIVERSITY_COMMUNITY = 'UNIVERSITY_COMMUNITY',
    OTHER = 'OTHER'
}

export enum IntroductionMethodTitle {
    SOCIAL_MEDIA = 'شبکه های اجتماعی',
    FRIENDS = 'دوستان',
    UNIVERSITY_COMMUNITY = 'انجمن های علمی',
    OTHER = 'سایر'
}

export enum ScientificAssociationRole {
    MEMBER = 'MEMBER',
    SECRETARY = 'SECRETARY'
}

export enum ScientificAssociationRoleTitle {
    MEMBER = 'عضو انجمن',
    SECRETARY = 'دبیر انجمن'
}

export enum FieldOfStudy {
    MATHEMATICS = 'MATHEMATICS',
    COMPUTER_SCIENCE = 'COMPUTER_SCIENCE',
    STATISTICS = 'STATISTICS',
    PHYSICS = 'PHYSICS',
    ENGINEERING_PHYSICS = 'ENGINEERING_PHYSICS',
    APPLIED_CHEMISTRY = 'APPLIED_CHEMISTRY',
    PURE_CHEMISTRY = 'PURE_CHEMISTRY',
    CHEMICAL_ENGINEERING = 'CHEMICAL_ENGINEERING',
    OTHER = 'OTHER'
}

export enum FieldOfStudyTitle {
    MATHEMATICS = 'ریاضی',
    COMPUTER_SCIENCE = 'علوم کامپیوتر',
    STATISTICS = 'آمار',
    PHYSICS = 'فیزیک',
    ENGINEERING_PHYSICS = 'فیزیک مهندسی',
    APPLIED_CHEMISTRY = 'شیمی کاربردی',
    PURE_CHEMISTRY = 'شیمی محض',
    CHEMICAL_ENGINEERING = 'مهندسی شیمی',
    OTHER = 'سایر'
}

export enum CourseStatus {
    COMING_SOON = 'COMING_SOON',
    IS_RECORDING = 'IS_RECORDING',
    COMPLETED = 'COMPLETED'
}

export enum CourseStatusTitle {
    COMING_SOON = 'به زودی',
    IS_RECORDING = 'در حال ضبط',
    COMPLETED = 'تکمیل'
}

export enum CourseStatusBadgeColor {
    COMING_SOON = 'red',
    IS_RECORDING = 'cyan',
    COMPLETED = 'green',
    EXERCISE_SOLUTION = 'blue'
}

export enum CourseTag {
    EXERCISE_SOLUTION = 'EXERCISE_SOLUTION'
}

export enum CourseTagTitle {
    EXERCISE_SOLUTION = 'حل تمرین'
}

export enum CourseTagBadgeColor {
    EXERCISE_SOLUTION = 'blue'
}

export enum UserPlanStatus {
    ACTIVE = 'ACTIVE',
    RESERVED = 'RESERVED',
    EXPIRED = 'EXPIRED'
}

export enum UserPlanStatusTitle {
    ACTIVE = 'فعال',
    RESERVED = 'رزرو',
    EXPIRED = 'منقضی شده'
}

export enum UserPlanStatusColor {
    ACTIVE = 'green',
    RESERVED = 'yellow',
    EXPIRED = 'volcano'
}

export enum FileType {
    LICENCE_NEED_VIDEO = 'LICENCE_NEED_VIDEO',
    LICENCE_NEED_FILE = 'LICENCE_NEED_FILE',
    FREE_VIDEOS = 'FREE_VIDEOS',
    FREE_FILE = 'FREE_FILE',
    TEACHER_FILES = 'TEACHER_FILES',
}

export enum FileTypeTitle {
    LICENCE_NEED_VIDEO = 'ویدئو لایسنس دار',
    LICENCE_NEED_FILE = 'فایل لاسنس دار',
    FREE_VIDEOS = 'ویدئو رایگان',
    FREE_FILE = 'فایل رایگان',
    TEACHER_FILES = 'فایل مدرسین',
}

export enum OSType {
    WINDOWS = 'WINDOWS',
    MAC = 'MAC',
    LINUX = 'LINUX',
    ANDROID = 'ANDROID',
    IOS = 'IOS',
    UNKNOWN = 'UNKNOWN',
}

export enum OSTypeTitle {
    WINDOWS = 'windows',
    MAC = 'mac',
    LINUX = 'linux',
    ANDROID = 'android',
    IOS = 'ios',
    UNKNOWN = 'unknown',
}

export enum BrowserType {
    CHROME = 'CHROME',
    FIREFOX = 'FIREFOX',
    OPERA = 'OPERA',
    INTERNET_EXPLORER = 'INTERNET_EXPLORER',
    MICROSOFT_EDGE = 'MICROSOFT_EDGE',
    SAFARI = 'SAFARI',
    UNKNOWN = 'UNKNOWN',
}

export enum BrowserTypeTitle {
    CHROME = 'chrome',
    FIREFOX = 'firefox',
    OPERA = 'opera',
    INTERNET_EXPLORER = 'internet explorer',
    MICROSOFT_EDGE = 'microsoft edge',
    SAFARI = 'safari',
    UNKNOWN = 'unknown',
}

export enum SessionStatus {
    LOGIN = 'LOGIN',
    LOGOUT = 'LOGOUT',
    FAILED = 'FAILED'
}

export enum TransactionStatus {
    PENDING = 'PENDING',
    WAIT_FOR_PAYMENT = 'WAIT_FOR_PAYMENT',
    SUCCESS = 'SUCCESS',
    FAILED = 'FAILED'
}

export enum TransactionStatusTitle {
    PENDING = 'در انتظار',
    WAIT_FOR_PAYMENT = 'در انتظار پرداخت',
    SUCCESS = 'موفق',
    FAILED = 'ناموفق'
}

export enum TransactionType {
    BUY_PLAN = 'BUY_PLAN',
    REFERRAL_REWARD = 'REFERRAL_REWARD',
    WITHDRAW = 'WITHDRAW'
}

export enum TransactionTypeColor {
    BUY_PLAN = 'red',
    REFERRAL_REWARD = 'green',
    WITHDRAW = 'red'
}


export enum TransactionTypeTitle {
    BUY_PLAN = 'خرید اشتراک',
    REFERRAL_REWARD = 'پاداش معرفی',
    WITHDRAW = 'برداشت وجه'
}

export enum PaymentGateway {
    ZARIN_PAL = 'ZARIN_PAL',
    NEXT_PAY = 'NEXT_PAY'
}

export enum WithdrawStatus {
    PENDING = 'PENDING',
    APPROVED = 'APPROVED',
    REJECTED = 'REJECTED'
}


export enum WithdrawStatusTitle {
    PENDING = 'در انتظار تایید',
    APPROVED = 'تایید شده',
    REJECTED = 'رد شده'
}