import React, {useEffect} from "react";
import {useSearchParams} from "react-router-dom";
import StorageService from "../../services/StorageService";
import UserService from "../../services/UserService";
import TokenService from "../../services/TokenService";

export const SaveRefCodeToStorage = () => {
    const [searchParams, setSearchParams] = useSearchParams()

    useEffect(() => {
        const ref = searchParams.get("ref");
        if (ref) {
            StorageService.setItem("ref", ref);
            if (TokenService.isLogin()){
                UserService.refreshRefAffiliateKey(ref);
            }
        }
    }, [searchParams])

    return(<></>);
}