import axios from "axios";
import {genericApiCall} from "./UtilFunctions";
import TokenService from "./TokenService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface WalletOutputModel {
    balance: number;
    blockedBalance: number;
    user?: User;
}

export interface User {
    firstName: string;
    lastName: string;
    username: string;
}

class WalletService {

    private static instance: WalletService;

    private constructor() {
    }

    public static getInstance(): WalletService {
        if (!WalletService.instance) {
            WalletService.instance = new WalletService();
        }
        return WalletService.instance;
    }

    async getSelfWallet(): Promise<WalletOutputModel> {
        return await genericApiCall<WalletOutputModel>(async () => {
            const response = await axios.get<WalletOutputModel>(baseUrl + "/api/v1/wallet/self", {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default WalletService.getInstance();