import axios from "axios";
import {genericApiCall, Page} from "./UtilFunctions";
import TokenService from "./TokenService";
import {WithdrawStatus} from "../constans/enums";
import {User} from "./UserService";

const baseUrl = process.env.REACT_APP_BASE_URL || "";

export interface WithdrawRequestOutputModel {
    id: number;
    createdDate: Date;
    amount: number;
    status: WithdrawStatus;
    cardNumber: string;
    trackId: string;
    adminComment: string;
}

export interface WithdrawRequestInputModel {
    amount:number;
}

export interface AdminWithdrawRequestOutputModel {
    id: number;
    createdDate: Date;
    amount: number;
    status: WithdrawStatus;
    cardNumber: string;
    trackId: string;
    adminComment: string;
    user:User;
    transactionId: string;
}

export interface UpdateWithdrawRequestInputModel {
    trackId?:string;
    adminComment?:string;
}

class WithdrawRequestService {

    private static instance: WithdrawRequestService;

    private constructor() {
    }

    public static getInstance(): WithdrawRequestService {
        if (!WithdrawRequestService.instance) {
            WithdrawRequestService.instance = new WithdrawRequestService();
        }
        return WithdrawRequestService.instance;
    }

    async getSelfWithdrawRequestPage(params: any): Promise<Page<WithdrawRequestOutputModel>> {
        return await genericApiCall<Page<WithdrawRequestOutputModel>>(async () => {
            const response = await axios.get<Page<WithdrawRequestOutputModel>>(baseUrl + "/api/v1/withdraw_request/self", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async requestWithdraw(body : WithdrawRequestInputModel): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.post<void>(baseUrl + "/api/v1/withdraw_request/request", body, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async getWithdrawRequestPage(params: any): Promise<Page<AdminWithdrawRequestOutputModel>> {
        return await genericApiCall<Page<AdminWithdrawRequestOutputModel>>(async () => {
            const response = await axios.get<Page<AdminWithdrawRequestOutputModel>>(baseUrl + "/api/v1/withdraw_request", {
                params,
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async approveWithdrawRequest(requestId:number, body : UpdateWithdrawRequestInputModel): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/withdraw_request/approve/" + requestId, body, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

    async rejectWithdrawRequest(requestId:number, body : UpdateWithdrawRequestInputModel): Promise<void> {
        return await genericApiCall<void>(async () => {
            const response = await axios.put<void>(baseUrl + "/api/v1/withdraw_request/reject/" + requestId, body, {
                headers: {
                    'Authorization': "Bearer " + await TokenService.getToken()
                }
            });
            return response.data;
        });
    }

}

export default WithdrawRequestService.getInstance();